/**
 *  invitation.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de registro de usuario para generar QR
 */

import React, {Component} from "react";
import HeaderSimple from "../components/header/header-simple";
import Request from "../core/httpClient";
import moment from "moment-timezone";
//import Logo from "../img/logo.png";
import Logo from "../img/LogoAppMosph2.png";
import imgQr from "../img/qr-code.png";
const request = new Request();

class Complete extends Component {
	constructor(props) {
		super(props);
		this.state = {
			countries: [],
			states: [],
			image: "",
			data: "",
			telephone: "",
			id: 0,
			created: true,
			expiracion: "",
			idToken: 0,
		};
	}

	render() {
		return (
			<div id="completeBody" className="column complete align-center justify-center">
				<HeaderSimple />
				<div className="justify-center align-center justify-center">
					{this.state.loading ? (
						<div className="container column">
							<div className="justify-center">
								<i className="fas fa-spinner fa-2x fa-spin"></i>
							</div>
						</div>
					) : this.state.showQR ? (
						this.renderQr()
					) : (
						this.renderForm()
					)}
				</div>
			</div>
		);
	}

	componentDidMount() {
		if (this.props.match.params) {
			this.load(this.props.match.params);
		}
		this.loadCountries();
	}

	/**
	 * Función que obtiene la información obtenida por el URL
	 */
	async load(params) {
		this.setState({loading: true});
		const response = await request.post("/invitaciones/get/databytoken", {token: params.id});
		if (response.error) {
			this.setState({error: response.error, message: response.message, loading: false});
		} else {
			if (response.created === 0) {
				if (response.number) {
					this.setState({
						telephone: response.number,
						id: response.id,
						vivienda: response.vivienda,
						inquilino: response.inquilino,
						created: false,
						expiracion: response.expiracion,
						idToken: response.idToken,
						loading: false,
						invitacion: response.invitacion,
						entrada1: response.entrada1,
						salida1: response.salida1,
						entrada2: response.entrada2,
						salida2: response.salida2,
						horaPermitida: response.horaPermitida,
						entradas: response.entradas ? response.entradas : 2,
						entradasUsadas: ((response.entrada1 === null? 0 : 1) * (response.salida1 === null? 0 : 1)) + ((response.entrada2 === null? 0 : 1) * (response.salida2 === null? 0 : 1))
					});
				} else {
					this.setState({error: response.error, message: response.message, loading: false});
				}
			} else {
				this.setState({message: "YA EXISTE EL USUARIO REGISTRADO EN APPMOSPHERA", loading: false});
			}
		}
	}

	/**
	 * Función que obtiene la lista de países
	 */
	async loadCountries() {
		const countries = await request.get("/fraccionamientos/get/countries");
		if (Array.isArray(countries)) {
			this.setState({countries});
		}
		this.loadStates();
	}

	/**
	 * Función que obtiene la lista de estados
	 */
	async loadStates() {
		let states = [];
		const country = document.getElementById("country");
		if (country) {
			states = await request.get(`/fraccionamientos/get/states/${country.value}`);
		} else {
			states = await request.get(`/fraccionamientos/get/states/${1}`);
		}
		if (Array.isArray(states)) {
			this.setState({states});
		}
	}

	/**
	 * Función para enviar los datos a generar QR
	 * @parms: data: object (id: int, number: string, vivienda: int, inquilino: string)
	 */
	async handleSubmit(event) {
		event.preventDefault();
		this.setState({loading: true, showQR: false});
		const form = event.target;
		const data = {
			id: this.state.id,
			number: this.state.telephone,
			name: form.name.value,
			vivienda: this.state.vivienda,
			inquilino: this.state.inquilino,
			expiracion: moment(this.state.expiracion).tz("America/Cancun").format("YYYY-MM-DD HH:mm:ss"),
			idToken: this.state.idToken,
			invitacion: this.state.invitacion,
		};
		const response = await request.post("/invitaciones/set/guestdata", data);
		console.log(response);
		if (response.qr) {
			this.setState({
				qr: `data:image/jpeg;base64,${response.qr}`,
				nombre_invitado: response.nombre_invitado,
				expiracion: response.expiracion,
				showQR: true,
				fraccionamiento: response.fraccionamiento,
				sub_fraccionamiento: response.sub_fraccionamiento,
			});
		}
		this.setState({message: response.message, loading: false});
	}

	/**
	 * Function para download QR
	 *
	 */
	download() {
		var link = document.createElement("a");
		var img = document.getElementById("imgQr").src;
		link.href = img;
		link.download = `AppMospheraInvitation${moment().format("YYYYMMDDHHmmss")}.jpg`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	/*
	funciones para render */

	expirado(){
		return <React.Fragment>
		<div className="white-space-24"></div>
		<div className="justify-center">
			<img
				id="imgQr"
				src={imgQr}
				alt="AppMosphera QR"
				title="AppMosphera QR"
				width="250px"
				height="250px"
			/>
		</div>
		<div className="white-space-24"></div>
		<p className="text-center font-regular">
			<b className="font-regular"> Su invitación ya expiró.</b>
		</p>
		<div className="white-space-24"></div>
		<div className="white-space-24"></div>
		<p className="text-center font-regular">
			<b className="font-regular"> Gracias por utilizar AppMosphera.</b>
		</p>
		<div className="white-space-24"></div>
		<div className="white-space-24"></div>
	</React.Fragment>
	}

	visibleHasta(){
		return <React.Fragment>
		<div className="white-space-24"></div>
		<div className="justify-center">
			<img
				id="imgQr"
				src={imgQr}
				alt="AppMosphera QR"
				title="AppMosphera QR"
				width="250px"
				height="250px"
			/>
		</div>
		<div className="white-space-24"></div>
		<p className="text-center font-regular">El código de acceso estará disponible </p>
		<div className="white-space-16"></div>
		{/* <p className="text-center font-regular">
			<b className="font-large">30 minutos</b>
		</p>
		<div className="white-space-16"></div> */}
		<p className="text-center font-regular">30 minutos antes de la llegada.</p>
		<div className="white-space-24"></div>
		<div className="white-space-24"></div>
		<p className="text-center ">
			<b className="font-regular"> Gracias por utilizar AppMosphera.</b>
		</p>
		<div className="white-space-24"></div>
		<div className="white-space-24"></div>
	</React.Fragment>
	}

	qrCode(){
		return <React.Fragment>
		<div className="white-space-16"></div>
		<div className="justify-center">
			<img
				id="imgQr"
				src={this.state.qr}
				alt="AppMosphera QR"
				title="AppMosphera QR"
				width="250px"
				height="250px"
			/>
		</div>
		<div className="white-space-24"></div>
		<div className="column">
			<p className="text-center font-regular uppercase">
				<b className="font-regular">
					{this.state.entrada1 === null
						? "Entrada"
						: this.state.salida1 === null
						? "Salida"
						: this.state.entrada2 === null
						? "Entrada"
						: "Salida"}
				</b>
			</p>
		</div>
		<div className="white-space-16"></div>
		<p className="text-center font-regular">
			Presenta este código QR junto a tu identificación en la caseta de seguridad.
		</p>
		<div className="white-space-16"></div>
		<div className="column">
			<button className="btn" onClick={this.download.bind(this)}>
				Descargar <i className="fas fa-download"></i>
			</button>
		</div>
	</React.Fragment>
	}
	/**
	 * Function render QR section
	 *
	 */
	renderQr() {
		document.getElementById("headerId").style.display = "none";
		document.getElementById("completeBody").classList.remove("complete");
		document.getElementById("completeBody").classList.add("invitation");	  

		return (
			<React.Fragment>
				<div className="white-space-32"></div>
				<div className="column align-center justify-center">
                <div className="white-space-32"></div>
					<p className="text-center">
						<b>{this.state.fraccionamiento}</b>
					</p>
					<div className="white-space-8"></div>
					<p className="text-center">
						Fecha: <b>{moment(this.state.expiracion).tz("America/Cancun").format("DD-MM-YYYY HH:mm")}</b>
					</p>
					<div className="white-space-8"></div>
					<p className="text-center">
						<b>{this.state.nombre_invitado}</b>
					</p>
					
					{
					new Date(moment().tz("America/Cancun").format("MM-DD-YYYY 00:00")) > new Date(moment(this.state.expiracion).tz("America/Cancun").format("MM-DD-YYYY 00:00") ) ? (
						this.expirado()
					) : new Date(moment(this.state.horaPermitida).tz("America/Cancun").format("MM-DD-YYYY HH:mm") ) > new Date( moment().tz("America/Cancun").format("MM-DD-YYYY HH:mm") )? 
						this.visibleHasta()
					:
					this.state.entradasUsadas < this.state.entradas ? (
						this.qrCode()
					) : (
						this.expirado()
					)
					
					}

					<div className="row justify-center align-center">
						<div className="column justify-center">
							<div className="line-black"></div>
						</div>
						<div className="column align-center">
							<img
								className="image-responsive"
								width="50%"
								height="auto"
								src={Logo}
								alt="Logo AppMosphera"
								title="Logo AppMosphera"
							/>
						</div>
						<div className="column justify-center">
							<div className="line-black"></div>
						</div>
					</div>
					<div className="white-space-16"></div>
				</div>
			</React.Fragment>
		);
	}

	/**
	 * Function render Form
	 *
	 */
	renderForm() {
		return (
			<React.Fragment>
				<form className="container column align-center justify-center" onSubmit={this.handleSubmit.bind(this)}>
					<div className="white-space-16"></div>
					<div className="white-space-4"></div>
					<div className="column justify-center align-center">
						<p className="font-large ">¡Nos interesa tu seguridad!</p>
						<div className="white-space-8"></div>
					</div>
					<div className="white-space-16"></div>
					<div className="row align-center justify-center condition">
						<input
							type="checkbox"
							className="input-textbox"
							id="acept_policy"
							name="acept_policy"
							required
						></input>
						<p className="">
							
							&nbsp; He leido y acepto la
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://appmosphera.com/politicas.html"
							>
								Politica de privacidad
							</a>
						</p>
					</div>
					<div className="white-space-16" />
					<button
						className="btn btn-primary btn-small color-white"
						disabled={this.state.created}
						type="submit"
					>
						ACEPTAR
					</button>
					<div className="white-space-16" />
					<div className="column align-center justify-center">
						<p className="weight-medium">{this.state.message}</p>
					</div>
				</form>
			</React.Fragment>
		);
	}
}

export default Complete;
