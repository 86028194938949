/**
 *  invitation.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Página de visualización del QR
 */

import React, {Component} from "react";
import moment from "moment-timezone";
import Request from "../core/httpClient";
//import Logo from "../img/logoblack.png";
//import LogoApp from "../img/logo.png";
import LogoApp from "../img/LogoAppMosph2N.png";
import imgQr from "../img/qr-code.png";
const request = new Request();

// TODO
// Modificar API y SP para mandar la INV en status 1 cuando se cree

class Invitation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			image: "",
			data: "",
			nombre_invitado: "",
			expiracion: moment(),
			fecha: moment(),
			valid: true,
		};
	}

	download() {
		var link = document.createElement("a");
		var img = document.getElementById("imgQr").src;
		link.href = img;
		link.download = `AppMospheraInvitation${moment().format("YYYYMMDDHHmmss")}.jpg`;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

	render() {
		return (
			<div className="invitation column">
				<div className="justify-center">
					<div className="container column">
						<div className="white-space-32"></div>
						<div className="justify-center align-center">
							{this.state.loading ? (
								<div className="column">
									<div className="justify-center">
										<i className="fas fa-spinner fa-2x fa-spin"></i>
									</div>
									<div className="white-space-16"></div>
									<p className="text-center font-regular weight-medium">Cargando invitación</p>
								</div>
							) : (
								<div className="column">{this.renderQr()}</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		if (this.props.match.params) {
			this.load(this.props.match.params);
		}
	}
	/**
	 * Función para render el QR
	 */

	expirado() {
		return (
			<React.Fragment>
				<div className="white-space-8"></div>
				<p className="text-center">
					<b className="font-large">{this.state.nombre_invitado}</b>
				</p>
				<div className="white-space-24"></div>
				<div className="justify-center">
					<img id="imgQr" src={imgQr} alt="AppMosphera QR" title="AppMosphera QR" width="250px" height="250px" />
				</div>
				<div className="white-space-24"></div>
				<p className="text-center font-regular">
					<b className="font-regular"> Su invitación ya expiró.</b>
				</p>
				<div className="white-space-24"></div>
				<div className="white-space-24"></div>
				<p className="text-center font-regular">
					<b className="font-regular"> Gracias por utilizar AppMosphera.</b>
				</p>
				<div className="white-space-24"></div>
				<div className="white-space-24"></div>
			</React.Fragment>
		);
	}

	visibleHasta() {
		return (
			<React.Fragment>
				<div className="white-space-16"></div>
				<p className="text-center">
					<b className="font-large">{this.state.nombre_invitado}</b>
				</p>
				<div className="white-space-24"></div>
				<div className="justify-center">
					<img id="imgQr" src={imgQr} alt="AppMosphera QR" title="AppMosphera QR" width="250px" height="250px" />
				</div>
				<div className="white-space-24"></div>
				<p className="text-center font-regular">El código de acceso estará disponible </p>
				<div className="white-space-16"></div>
				{/* <p className="text-center font-regular">
					<b className="font-large">30 minutos</b>
				</p>
				<div className="white-space-16"></div> */}
				<p className="text-center font-regular">30 minutos antes de la llegada.</p>
				<div className="white-space-24"></div>
				<div className="white-space-24"></div>
				<p className="text-center ">
					<b className="font-regular"> Gracias por utilizar AppMosphera.</b>
				</p>

				<div className="white-space-24"></div>
				<div className="white-space-24"></div>
			</React.Fragment>
		);
	}

	qrcode() {
		return (
			<React.Fragment>
				<div className="white-space-8"></div>
				<p className="text-center">
					<b className="font-large">{this.state.nombre_invitado}</b>
				</p>
				<div className="white-space-16"></div>
				<div className="justify-center">
					<img id="imgQr" src={this.state.qr} alt="AppMosphera QR" title="AppMosphera QR" width="250px" height="250px" />
				</div>
				<div className="white-space-16"></div>
				<div className="column">
					<p className="text-center font-regular uppercase">
						<b className="font-regular">
							{this.state.entrada1 === null ? "Entrada" : this.state.salida1 === null ? "Salida" : this.state.entrada2 === null ? "Entrada" : "Salida"}
						</b>
					</p>
				</div>
				<div className="white-space-16"></div>
				<p className="text-center font-regular">Presenta este código QR junto a tu identificación en la caseta de seguridad.</p>
				<div className="white-space-16"></div>
				<div className="column">
					<button className="btn" onClick={this.download.bind(this)}>
						Descargar <i className="fas fa-download"></i>
					</button>
				</div>
			</React.Fragment>
		);
	}

	renderMesage() {
		return (
			<React.Fragment>
				<div className="white-space-24"></div>
				<div className="justify-center">
					<img id="imgQr" src={imgQr} className="qrdefault" alt="AppMosphera QR" title="AppMosphera QR" />
				</div>
				<div className="white-space-24"></div>
				<p className="text-center font-regular">
					<b className="font-regular"> {this.state.message}</b>
				</p>
				<div className="white-space-24"></div>
				<div className="white-space-24"></div>
				<p className="text-center ">
					<b className="font-regular"> Gracias por utilizar AppMosphera.</b>
				</p>
				<div className="white-space-24"></div>
				<div className="white-space-24"></div>
			</React.Fragment>
		);
	}

	renderQr() {
		return (
			<React.Fragment>
				<div className="column">
					<p className="text-center">
						<b>{this.state.fraccionamiento}</b>
					</p>
					<div className="white-space-8"></div>
					<p className="text-center">
						Fecha: <b>{moment(this.state.fecha).tz("America/Cancun").format("DD-MM-YYYY HH:mm")}</b>
					</p>
					{!this.state.valid
						? this.renderMesage()
						: new Date(moment().tz("America/Cancun").format("MM-DD-YYYY 00:00")) > new Date(moment(this.state.expiracion).tz("America/Cancun").format("MM-DD-YYYY 00:00"))
						? this.expirado()
						: new Date(moment(this.state.horaPermitida).tz("America/Cancun").format("MM-DD-YYYY HH:mm")) > new Date(moment().tz("America/Cancun").format("MM-DD-YYYY HH:mm"))
						? this.visibleHasta()
						: this.state.entradasUsadas < this.state.entradas
						? this.qrcode()
						: this.expirado()}

					<div className="row justify-center align-center">
						<div className="column justify-center">
							<div className="line-black"></div>
						</div>
						<div className="column align-center">
							<img className="image-responsive" width="50%" height="auto" src={LogoApp} alt="Logo AppMosphera" title="Logo AppMosphera" />
						</div>
						<div className="column justify-center">
							<div className="line-black"></div>
						</div>
					</div>
					<div className="white-space-16"></div>
				</div>
			</React.Fragment>
		);
	}

	/**
	 * Función que obtiene la información obtenida por el URL
	 */
	async load(params) {
		this.setState({loading: true});
		const response = await request.post("/invitaciones/get/databytoken", {
			token: params.id,
		});
		// console.log(response);
		// console.log(response.expiracion);
		// console.log(moment(response.expiracion).tz("America/Cancun").format("DD-MM-YYYY HH:mm"));
		if (response.completar) {
			window.location = `/complete/${params.id}`;
			return;
		}
		if (response.error) {
			this.setState({
				error: response.error,
				message: response.message,
				loading: false,
				valid: false,
			});
			return;
		} else {
			if (response.number) {
				this.setState({
					telephone: response.number,
					id: response.id,
					vivienda: response.vivienda,
					inquilino: response.inquilino,
					expiracion: response.expiracion,
					fecha: response.fecha,
					entradas: response.entradas ? response.entradas : 2,
				});
				const data = {
					id: response.id,
					number: response.number,
					vivienda: this.state.vivienda,
					inquilino: this.state.inquilino,
					expiracion: response.expiracion,
					idToken: response.idToken,
					invitacion: response.invitacion,
				};
				const dataValidate = {idInvitacion: response.invitacion};
				const responseValid = await request.post("/invitaciones/validar", dataValidate);
				if (responseValid && !responseValid.error) {
					if (responseValid.status === 1) {
						let responseInvitation = "";
						if (response.invitacion) {
							responseInvitation = await request.post("/invitaciones/get/invitation", data);
						} else {
							responseInvitation = await request.post("/invitaciones/set/invitation", data);
						}
						if (responseInvitation.qr) {
							await this.setState({
								qr: `data:image/jpeg;base64,${responseInvitation.qr}`,
								nombre_invitado: responseInvitation.nombre_invitado,
								expiracion: responseInvitation.expiracion,
								fraccionamiento: responseInvitation.fraccionamiento,
								sub_fraccionamiento: responseInvitation.sub_fraccionamiento,
								showQR: true,
								valid: true,
								loading: false,
								entrada1: responseInvitation.entrada1,
								salida1: responseInvitation.salida1,
								entrada2: responseInvitation.entrada2,
								salida2: responseInvitation.salida2,
								horaPermitida: responseInvitation.horaPermitida,
								entradasUsadas:
									(responseInvitation.entrada1 === null ? 0 : 1) * (responseInvitation.salida1 === null ? 0 : 1) +
									(responseInvitation.entrada2 === null ? 0 : 1) * (responseInvitation.salida2 === null ? 0 : 1),
							});
						} else {
							this.setState({
								error: responseInvitation.error,
								message: responseInvitation.message,
								loading: false,
								valid: false,
							});
						}
					} else if (responseValid.status === 2) {
						this.setState({
							error: true,
							message: "La invitación ha expirado",
							loading: false,
							valid: false,
						});
					} else if (responseValid.status === 3) {
						this.setState({
							error: true,
							message: "La invitación ya ha sido utilizada",
							loading: false,
							valid: false,
						});
					} else {
						this.setState({
							error: true,
							message: "La invitación ha sido cancelada",
							loading: false,
							valid: false,
						});
					}
				} else {
					alert("ERROR DE CONEXIÓN");
					this.setState({
						error: responseValid.error,
						message: responseValid.message,
						loading: false,
						valid: false,
					});
				}
			}
		}
	}
}

export default Invitation;
