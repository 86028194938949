/**
 *  header-simple.jsx
 *  @version: 1.0.0
 *  @author: DWIT MÉXICO - Sergio
 *  @description: Componente header simple para no-usuarios
 */

import React, { Component } from "react";
import Logo from "../../img/LogoAppMosph2.png";

class HeaderSimple extends Component {	

	render() {
		return (
			<header id="headerId" className="justify-center">
				<div className="container">
					<div className="justify-center">
						<div className="logo align-center justify-center ">
							<a href="#" className="responsive-img">
								<img
									src={Logo}
									alt="Logo AppMosphera"
									title="Logo AppMosphera"
								/>
							</a>
						</div>
					</div>
				</div>
				<div className="white-space-16"></div>
			</header>
		);
	}	
}

export default HeaderSimple;
